<template>
<div>
    <section class="section-border border-primary">
        <div class="container d-flex flex-column">
            <div class="row align-items-center justify-content-center gx-0 min-vh-100">
                <div class="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
                    <img src="/assets/img/illustrations/illustration-8.png" alt="..." class="img-fluid">
                </div>
                <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">
                    <h1 class="mb-0 fw-bold text-center">Registration</h1>
                    <p class="mb-6 text-center text-muted" v-if="Object.keys(errors).length < 1">
                        Register to get access to the API token
                    </p>
                    <p class="mb-6 text-center text-muted" v-show="$root.context.promocode && $root.context.free_requests">
                        {{ $root.context.free_requests }} free requests for new clients!
                    </p>

                    <p class="mb-6 text-center text-danger" v-for="error in errors.__all__">{{ error }}</p>
                    <form class="mb-6" @submit.prevent="register">
                        <div class="form-group">
                            <label class="form-label" for="email">Email</label>
                            <input type="email" class="form-control" :class="{'is-invalid': errors.email}" id="email" placeholder="Enter your Email" v-model="form.email">
                            <small class="form-text text-danger" v-for="error in errors.email">{{ error }}</small>
                        </div>
                        <div class="form-group mb-5">
                            <label class="form-label" for="password">Password</label>
                            <input type="password" class="form-control" :class="{'is-invalid': errors.password}" id="password" placeholder="Enter your password" v-model="form.password">
                            <small class="form-text text-danger" v-for="error in errors.password">{{ error }}</small>
                        </div>
                        <div class="form-group mb-5">
                            <label class="form-label" for="password2">Repeat password</label>
                            <input type="password" class="form-control" :class="{'is-invalid': errors.password2}" id="password2" placeholder="Enter your password again" v-model="form.password2">
                            <small class="form-text text-danger" v-for="error in errors.password2">{{ error }}</small>
                        </div>
			<!-- Turnstile widget -->
                        <div class="cf-turnstile"></div>
                        <div class="form-group mb-5 fs-sm">
                            <label :class="{'is-invalid': errors.agree_terms_of_service}">
                                <input type="checkbox" class="form-check-input" v-model="form.agree_terms_of_service">
                                I accept the
                                <router-link :to="{path: '/help/terms-of-service'}">Terms of service</router-link>
                            </label>
                            <label :class="{'is-invalid': errors.agree_privacy_policy}">
                                <input type="checkbox" class="form-check-input" v-model="form.agree_privacy_policy">
                                I accept the
                                <router-link :to="{path: '/help/privacy-policy'}">Privacy Policy</router-link>
                            </label>
                        </div>
                        <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">Register</button>
                    </form>
                    <p class="mb-0 fs-sm text-center text-muted">
                        Already have an account?
                        <router-link :to="{path: '/login'}">Then login</router-link>.
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import { EventBus } from "@/bus";
import { displayErrors } from "utils/string"
export default {
    data() {
        return {
            form: {
                agree_terms_of_service: true,
                agree_privacy_policy: true,
                cf_turnstile_response: ''
            },
            errors: {},
            disabled: false
        }
    },
    created() {
        EventBus.$on('context:loaded', context => {
            if (!context.id) return
            this.$router.replace({path: '/dashboard'})
        })
        EventBus.$emit('context:request')
    },
    mounted() {
        setTimeout(() => {
            this.initializeTurnstile();
        }, 1000);
        window.onTurnstileSuccess = this.onTurnstileSuccess.bind(this);
    },
    methods: {
        initializeTurnstile() {
            const element = document.querySelector(".cf-turnstile");
            if (!element) {
                console.error("Turnstile element not found.");
                return;
            }
            window.turnstile.render(element, {
                sitekey: "0x4AAAAAAAzu_evKuPaGl6H6",
                callback: this.onTurnstileSuccess,
            });
        },
        onTurnstileSuccess(token) {
           this.form.cf_turnstile_response = token;
        },
        register() {
            if (!this.form.cf_turnstile_response) {
               alert("CloudFlare check has failed!\nVerify you are human, please.");
               return;
            }
            this.disabled = true
            this.errors = {}
            if (this.$root.context.promocode) {
                this.form.promocode = this.$root.context.promocode;
            } else {
                this.form.promocode = localStorage.getItem("promocode");
            }
            this.$root.Register.save(this.form).then(resp => {
                this.errors = {}
                if (resp.body.state) {
                    EventBus.$emit('context:changed')
                    setTimeout(() => {
                        this.$router.replace({path: '/tokens'})
                    })
                }
                displayErrors(resp, this);
                this.disabled = false
            }).catch(resp => {
                displayErrors(resp, this);
                this.disabled = false
            })
            setTimeout(() => {
                this.disabled = false
            }, 10000)
        }
    }
}
</script>
<style scoped>
.is-invalid { color: red }
.is-invalid a { color: brown }
</style>
