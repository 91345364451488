var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("h1", { staticClass: "mb-0 fw-bold" }, [_vm._v("Dashboard")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-6 text-muted" }, [
              _vm._v("Request statistics for your usage"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.token_id,
                        expression: "token_id",
                      },
                    ],
                    staticClass: "form-select form-select-xs",
                    attrs: { name: "token_id" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.token_id = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: 0 } }, [
                      _vm._v("By all tokens"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.data.tokens, function (item) {
                      return _c(
                        "option",
                        { key: item.id, domProps: { value: item.id } },
                        [
                          _vm._v(
                            _vm._s(
                              item.is_active
                                ? item.name
                                : `${item.name} [deleted]`
                            )
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.daterange,
                        expression: "daterange",
                      },
                    ],
                    staticClass: "form-select form-select-xs",
                    attrs: { name: "daterange" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.daterange = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { disabled: "" } }, [
                      _vm._v("Date range:"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.data.dateranges, function (item) {
                      return _vm.displayDaterange(item.ident)
                        ? _c(
                            "option",
                            {
                              key: item.ident,
                              domProps: { value: item.ident },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.group_by,
                        expression: "group_by",
                      },
                    ],
                    staticClass: "form-select form-select-xs",
                    attrs: { name: "group_by" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.group_by = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { disabled: "" } }, [
                      _vm._v("Group by:"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.data.groups, function (item) {
                      return _vm.displayGroup(item.ident)
                        ? _c(
                            "option",
                            {
                              key: item.ident,
                              domProps: { value: item.ident },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("highcharts", { attrs: { options: _vm.chartOptions } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c("vuetable", {
                  ref: "vuetable",
                  staticClass: "ui celled table unstackable table-scroll",
                  attrs: {
                    "api-mode": false,
                    data: _vm.tableData,
                    "wrapper-class": "vuetable-wrapper ui basic segment",
                    "table-wrapper": ".vuetable-wrapper",
                    fields: _vm.fields,
                  },
                  on: { "vuetable:pagination-data": _vm.onPaginationData },
                  scopedSlots: _vm._u([
                    {
                      key: "a2_cnt",
                      fn: function (props) {
                        return [
                          _vm._v(_vm._s(_vm.findSum(props.rowData, "a2"))),
                        ]
                      },
                    },
                    {
                      key: "a2_user",
                      fn: function (props) {
                        return [
                          _vm._v(_vm._s(_vm.findSum(props.rowData, "a2_user"))),
                        ]
                      },
                    },
                    {
                      key: "gql_cnt",
                      fn: function (props) {
                        return [
                          _vm._v(_vm._s(_vm.findSum(props.rowData, "gql"))),
                        ]
                      },
                    },
                    {
                      key: "gql_user",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(_vm.findSum(props.rowData, "/gql/user"))
                          ),
                        ]
                      },
                    },
                    {
                      key: "gql_comments",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(_vm.findSum(props.rowData, "gql_comments"))
                          ),
                        ]
                      },
                    },
                    {
                      key: "gql_comment_likers",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.findSum(props.rowData, "gql_comment_likers")
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: "v1_cnt",
                      fn: function (props) {
                        return [
                          _vm._v(_vm._s(_vm.findSum(props.rowData, "v1"))),
                        ]
                      },
                    },
                    {
                      key: "v1_user",
                      fn: function (props) {
                        return [
                          _vm._v(_vm._s(_vm.findSum(props.rowData, "v1_user"))),
                        ]
                      },
                    },
                    {
                      key: "v1_location",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(_vm.findSum(props.rowData, "v1_location"))
                          ),
                        ]
                      },
                    },
                    {
                      key: "v1_hashtag",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(_vm.findSum(props.rowData, "v1_hashtag"))
                          ),
                        ]
                      },
                    },
                    {
                      key: "v1_highlight",
                      fn: function (props) {
                        return [
                          _vm._v(
                            _vm._s(_vm.findSum(props.rowData, "v1_highlight"))
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("vuetable-pagination", {
                  ref: "pagination",
                  on: { "vuetable-pagination:change-page": _vm.onChangePage },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }